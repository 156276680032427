<template>
    <div>
        <Preloader v-if='loading' />
        <div class='row position-relative' v-if='message'>
            <div class='col-6'>
                <div class='campaign__form'>
                    <div class='message-container'>
                        <h3 class='message-title'>{{ message.subject }}</h3>
                        <div class='message-info'>
                            <div class='message-label'>Дата создания</div>
                            <div class='message-value'>{{ message.created | formatDate }}</div>
                        </div>

                        <div class='message-info'>
                            <div class='message-label'>Отправитель</div>
                            <div class='message-value'>{{ message.sender_email }}</div>
                        </div>

                        <div class='message-info'>
                            <div class='message-label'>Имя отправителя/Организации</div>
                            <div class='message-value'>{{ message.sender_name }}</div>
                        </div>

                        <div class='message-info'>
                            <div class='message-label'>Тип рассылки</div>
                            <div class='message-value'>{{ message.service_type }}</div>
                        </div>

                        <div class='message-info'>
                            <div class='message-label'>Список контактов</div>
                            <EmailContact class='message-contact' :contact='contact' :disabled='true' />
                        </div>
                    </div>

                    <div class='d-flex campaign__stats' v-if='stats'>
                        <div class='message-info w-25'>
                            <div class='message-label text-center'>Отправлено</div>
                            <div class='message-value text-center message-value__stat'>{{ stats.sent }}</div>
                        </div>

                        <div class='message-info w-25'>
                            <div class='message-label text-center'>Доставлено</div>
                            <div class='message-value text-center message-value__stat'>{{ stats.delivered }}</div>
                        </div>

                        <div class='message-info w-25'>
                            <div class='message-label text-center'>Прочтено</div>
                            <div class='message-value text-center message-value__stat'>{{ stats.read_all }}</div>
                        </div>

                        <div class='message-info w-25'>
                            <div class='message-label text-center'>Переходы</div>
                            <div class='message-value text-center message-value__stat'>{{ stats.clicked_all }}</div>
                        </div>
                    </div>

                    <div class='message-container mt-3 links-table' v-if='visitedLinks'>
                        <table class='table'>
                            <thead>
                            <td>email</td>
                            <td>ссылка</td>
                            <td>время</td>
                            </thead>
                            <tbody>
                            <tr v-for='(item, idx) in visitedLinks.data' :key='idx'>
                                <td>{{ item[0] }}</td>
                                <td>{{ item[1] }}</td>
                                <td>{{ item[2] }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class='col-6 campaign__messages'>
                <div class='template__box' v-html='form.body'></div>
            </div>
        </div>
    </div>
</template>

<script>
import EmaiMessageList from '../../components/EmaiMessageList';
import Preloader from '../../components/Preloader';
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
import moment from 'moment';
import messages from '../../components/Notifications/NotificationsDemo/Messages';
import EmailContact from '../../components/EmailContact';
import EmailContactList from '../../components/EmailContactList';

export default {
    name: 'EmailDetails',
    components: { EmailContactList, EmailContact, Preloader, EmaiMessageList },
    data() {
        return {
            message: null,
            contact: null,
            stats: null,
            loading: false,
            visitedLinks: null,
            messages: null,
            editMessageMode: false,
            form: {
                messageLabel: null,
                message: null,
                timezone: null,
                date: null,
                time: null,
                track_read: false,
                track_links: false,
                contacts: null,
                body: null,
            },
        };
    },
    computed: {
        messageState() {
            return this.form.messages ? this.form.messages.length > 0 : false;
        },
        messageFeedback() {
            return 'необходимо выбрать шаблон сообщений';
        },
        ...mapGetters({
            getAllTimezones: 'common/getAllTimezones',
        }),
    },
    filters: {
        formatDate(value) {
            return new moment(value).format('DD.MM.YYYY H:m:s');
        },
    },
    methods: {
        pickMessage(id, subject) {
            this.form.message = id;
            this.form.messageLabel = subject;
        },
        async fetchMessages() {
            try {
                this.toggleLoading();
                const promiseList = (() => {
                    const res = [];
                    res.push(this.$axios.get(`api/mailing/email/message/${this.$route.params.id}`, {
                        params: {
                            campaign_id: this.$route.query.campaignId,
                        },
                    }));
                    res.push(this.$axios.get(`api/mailing/email/campaign/stats/${this.$route.query.campaignId}/visited`));
                    return res;
                })();
                const [currentMessage, visitedLinks] = await Promise.all(promiseList);

                console.log('0000000000000', visitedLinks);

                if (currentMessage && currentMessage.data && currentMessage.error) {
                    throw currentMessage['message'];
                }

                if (currentMessage && currentMessage.data && currentMessage.data.message) {
                    this.message = currentMessage.data.message;
                    this.contact = currentMessage.data.contact;
                    this.visitedLinks = visitedLinks.data.result;
                    this.stats = currentMessage.data.campaign.result;
                    const message = currentMessage.data.message;
                    this.form.message = message.id;
                    this.form.body = message.body;
                    this.form.messageLabel = message.subject;

                    const time = new moment(message.created);

                    this.form.time = time.format('H:m:s');
                    this.form.date = time.format('YYYY-MM-DD');
                    // this.form = message.messageLabel
                }

                // if (data && data.result) {
                //     this.messages = data.result;
                // }
                // console.log(data);
            } catch (e) {
                this.$toasted.error('Произошла ошибка при загрузке', {
                    position: 'bottom-right',
                });
            }
            this.toggleLoading();
        },
        async addData() {
            await this.$router.push({ name: 'EmailCreate' });
        },
        toggleLoading() {
            this.loading = !this.loading;
        },
        async handleSubmit() {
            this.toggleLoading();
            try {
                let datetime;
                if (this.form.date && this.form.time) {
                    datetime = new dayjs(this.form.date + ' ' + this.form.time).format('DD.MM.YYYY hh:mm');
                }

                const createPayload = () => {
                    const reqs = ['message', 'timezone', 'track_read', 'track_links'];
                    const payload = {};
                    for (const item of reqs) {
                        payload[item] = this.form[item];
                    }
                    return payload;
                };

                const { data } = await this.$axios.post('api/mailing/email/campaign', {
                    datetime,
                    ...createPayload(),
                });

                if (data && data.error) {
                    this.$toasted.error('произошла ошибка при запуске кампании', { position: 'bottom-right' });
                    return;
                }

                if (data && data.result) {
                    this.$toasted.success('Кампания создана', { position: 'bottom-right' });
                    return;
                }

            } catch (e) {
                this.$toasted.error('произошла ошибка запроса', { position: 'bottom-right' });
            }
            this.toggleLoading();
        },
    },
    beforeDestroy() {
        this.$store.commit('setEmailMessageCampaign', null);
    },
    async created() {
        if (this.$route.name === 'EmailCampaignEdit' && this.$route.params.id) {
            this.editMessageMode = true;
        }
        await this.fetchMessages();
    },
};
</script>

<style scoped lang='scss'>
.btn__add {
    height: 30px;
    //float: right;
    color: #333;
    font-weight: bold;
    text-transform: uppercase;
    //border: 1px solid #333;
    background: none;
}

.campaign__form {
    position: sticky;
    top: 20px;
}

.message-title {
    font-weight: 600;
}

.message {
    &-container {
        background: #fff;
        padding: 15px;
        border-radius: 10px;
        box-shadow: 0px 0px 15px -5px rgba(#000, .2);
    }

    &-label {
        font-size: 14px;
        font-weight: 300;
        margin-top: 15px;
    }

    &-value {
        font-size: 16px;
        font-weight: 500;
        border-bottom: 1px solid #f5f5f5;

        &:last-child {
            border-bottom: none;
        }

        &__stat {
            font-weight: 500;
        }
    }

    &-contact {
        ::v-deep {
            margin-top: 5px;
            padding: 0;
        }
    }
}

.campaign__stats {
    background: #fff;
    box-shadow: 0px 0px 15px -5px rgba(#000, .2);
    margin-top: 15px;
    border-radius: 10px;
    padding-bottom: 15px;
}
.links-table {
    height: 350px;
    overflow-y: auto;
    tr {
       &:nth-child(even) {
           background: #f5f5f5;
       }
    }
}
</style>