<template>
  <div class="email__contacts">
    <div class="contact__list">
      <EmailContact
          v-for="contact in contacts"
          @contactPicked="handlePick"
          :active-contact="pickedContact"
          :contact="contact"
          :key="contact.id"/>
    </div>
  </div>
</template>

<script>
import EmailContact from "./EmailContact";

export default {
  name: "EmailContactList",
  components: {EmailContact},
  props: ['contacts'],
  data() {
    return {
      pickedContact: null,
    }
  },
  methods: {
    handlePick(data) {
      this.pickedContact = data;
      this.$emit('contactPicked', this.pickedContact)
      console.log(data)
    }
  }
}
</script>

<style lang="scss" scoped>
.contact__list {
  display: flex;
  flex-wrap: wrap;
}

</style>